import { render, staticRenderFns } from "./DataPanelDetailView.vue?vue&type=template&id=17c8886d&scoped=true&"
import script from "./DataPanelDetailView.vue?vue&type=script&lang=js&"
export * from "./DataPanelDetailView.vue?vue&type=script&lang=js&"
import style0 from "./DataPanelDetailView.vue?vue&type=style&index=0&id=17c8886d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17c8886d",
  null
  
)

export default component.exports