<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    viewBox="0 96 960 960"
    width="20"
  >
    <path d="M480 723 240 483l51-51 189 189 189-189 51 51-240 240Z" />
  </svg>
</template>

<script>
export default {
  name: "ArrowDownIcon",
};
</script>
