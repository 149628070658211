<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>arrow_back_black_24dp</title>
    <g
      id="Admin"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Kaart---Zoekbalk" transform="translate(-24.000000, -24.000000)">
        <g
          id="arrow_back_black_24dp"
          transform="translate(16.000000, 16.000000)"
        >
          <g transform="translate(8.000000, 8.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            <polygon
              id="Path"
              fill="#000000"
              fill-rule="nonzero"
              points="20 11 7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ArrowLeftIcon",
};
</script>
